'use client';
import { validatePasscode } from 'app/actions/validate-passcode';
import { useActionState } from 'react';

const PasscodeForm: React.FC = () => {
  const [state, formAction, pending] = useActionState(validatePasscode, null);

  return (
    <form action={formAction}>
      <label htmlFor="passcode" className="sr-only">
        Enter Pass code.
      </label>
      <input
        type="password"
        name="passcode"
        placeholder="Passcode"
        className="mb-4 w-full border p-2"
        required
      />
      <button
        type="submit"
        className="rounded bg-blue-500 px-4 py-2 text-white"
        aria-disabled={pending}
      >
        Submit
      </button>

      <p aria-live="polite" className="text-red-600" role="status">
        {state?.message}
      </p>
    </form>
  );
};

export default PasscodeForm;
